<template>

      <!-- Transaction -->
      <v-container id="home">
        <v-row dense>
          <v-col cols="12" md="3">
            
            <h3>Your Transaction</h3>
            <!--End of Show-->
            <p class="text-caption text-uppercase mb-1 text-secondary">filters</p>
                  <v-list :disabled="disabled.transaction" >
                    <v-list-item-group v-model="dropdown.transaction" mandatory>
                 
                      <v-list-item
                        @click.prevent="getTransaction(null)"
                        class="Active"
                        active-class="indigo lighten-4 rounded"
                        
                      >
                        All</v-list-item
                      >
                      <v-list-item @click.prevent="getDay(null)"
                      active-class="indigo lighten-4 rounded"
                        >Today
                      </v-list-item>
                      <v-list-item @click.prevent="getWeek(null)"
                      active-class="indigo lighten-4 rounded"
                        >This Week</v-list-item
                      >
                    </v-list-item-group>
                  </v-list>
          </v-col>
          <v-col cols="12" md="9">
          <!-- <p class="text-dark"> Information for order or transaction  can be added here... </p> -->

            <v-card outlined elevation="0" id="paymentCard">
                <v-data-table
                  :headers="headers.transaction"
                  :items="arrays.transaction"
                  :items-per-page="10"
                  :hide-default-footer="true"
                  :loading="loadings.transaction"
                  disable-sort
                >
                <template v-slot:[`item.name`]="{ item }" >
                <a href="#" @click="go(item.products.link)"> {{ item.products.name }} </a>
                </template>
                <template v-slot:[`item.transaction_date`]="{ item }">
                  {{ item.date_time | displayDatev2 }}
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <span v-if="item.amount"> {{ item.amount | currency(true) }}</span>
                  <span v-else> {{ 0 | currency }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip top color="#0A009B">
                    <template v-slot:activator="{ attrs, on}">
                      <v-btn icon color="#0A009B"  small  @click="details(item)" v-bind="attrs" v-on="on">
                        <v-icon >mdi-view-headline</v-icon >
                      </v-btn>
           
                    </template>
                               <span> More Details  </span>
                  </v-tooltip>
                 
                  </template>
                </v-data-table>

                <Pagination
                  v-bind:meta="pagination.transaction"
                  @page="paginateTransaction"
                >
                </Pagination>
              <Details
                v-model="dialogs.details"
                v-bind:dialog="dialogs.details"
                v-bind:recievedData="dialogs.data"
              >
              </Details>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

</template>
<script>
import Pagination from "@/components/Pagination";
import Details from "@/components/OrderHistory/Transaction/details";
export default {
  props: {
    myInfo : Object
  },
  components: {
    Pagination,
    Details,
  },
  data() {
    return {
      arrays : {
        me : null,
        transaction: [],
        subscription : [],
      },
      pagination : {
        transaction : null,
        subscription : null,
        page : {
          transaction : null,
          subscription: null,
        }
      },
      headers : {
        transaction : [
           {
          text: "Product name",
          value: "name",
          },
          {
            text: "Transaction Date",
            value: "transaction_date",
          },
          {
            text: "Amount",
            align: "center",
            value: "amount",
          },
          {
            text: "Action",
            align: "center",
            value: "action",
          },
        ],
        subscription : [
          {
            text: "Product name",
            value: "products.name",
          },
          {
            text: "Type",
            value: "products.type",
          },
          {
            text: "Date Started",
            value: "start_of_subscription",
          },
          { 
            text: "Date End",
            value: "end_of_subscription"
          }
        ]
      },
      sort : {
        desc : true,
        by : "created_at" || "end_of_subscription"
      },
      loadings: {
        transaction : false,
        subscription : false,
      },
      common : {
        user_company_id : null,
        user_id : null,
      },
      dialogs: {
        details: false,
        data: null,
      },
      status : {
        admin : false
      },
      disabled : {
        transaction : true,
        subscription : true,
      },
      dropdown: {
        transaction : 0,
        subscription : 0
      },
      conditions : {
        transaction : 'all',
        subscription : 'all'
      },
      open : 0
    };
  },
  watch: {
    myInfo : function()
    {
      this.getInit()
    }
  },
  created() {
    if(this.myInfo)
    {
      this.getInit()
    }
  },
  methods: {
    go(url)
    {
      window.open(url)
    },
    getInit()
    {
      this.arrays.me = this.myInfo
      this.common.user_company_id = this.arrays.me.default_user_company.id
      this.common.user_id = this.arrays.me.id
      this.getTransaction();
    },
    details(data) {
      this.dialogs.details = true;
      this.dialogs.data = data;
    },
    getTransaction(page) {
      this.conditions.transaction = "all";
      let p = 1;
      if (page) {
        p = page;
      }
      this.loadings.transaction = true;
      this.axios
        .get(
          "/accounting?history=true&user_id=" + this.common.user_id + "&page=" + p
        )
        .then(({ data }) => {
          this.constructLink(data.accounting)
          this.arrays.transaction = data.accounting;
          if (!page) {
            this.pagination.transaction = data.meta;
          }
          this.loadings.transaction = false;
        })
        .catch()
        .finally( () => {
          this.disabled.transaction = false
        })
    },
    constructLink(subscription)
    {
      if(subscription && subscription.length > 0)
      {
        for(let i in subscription)
        {
          if(subscription[i].products && subscription[i].products.name )
          {
            let name =  subscription[i].products.name.toLowerCase().replaceAll(' ','')
            if(name == 'timeinpro')
            {
              subscription[i].products.link = this.$options.filters.productLink(name, subscription[i].products.url, this.common.user_company_id )
            }
          }
        }
      }
    },
    getDay(page) {
      this.loadings.transaction = true;
      this.conditions.transaction = "day";
      let date  = this.$options.filters.todaynoTime();
      let p = "";
      if (page) {
        p = page;
      }
      this.axios
        .get(
          "accounting?history=true&user_id=" +
            this.common.user_id +
            "&today=" +
            date +
            "&page=" +
            p
        )
        .then(({ data }) => {
          this.arrays.transaction = data.accounting;
          if (!page) {
            this.pagination.transaction = data.meta;
          }
          this.loadings.transaction = false;

           if(page)
          {
            this.scrollTable()
          }
          
        })
        .catch();
    },
    getWeek(page) {
      this.loadings.transaction = true;
      this.conditions.transaction = "week";
      let p = "";
      if (page) {
        p = page;
      }
      this.axios
        .get(
          "accounting?history=true&user_id=" +
            this.common.user_id +
            "&week_start=" +
            this.$options.filters.firstDayOfWeek()+
            '&week_end=' +
            this.$options.filters.lastDayofWeek() + 
            "&page=" +
            p
        )
        .then(({ data }) => {
          this.arrays.transaction = data.accounting;
          if (!page) {
            this.pagination.transaction = data.meta;
          }
          if(page)
          {
            this.scrollTable()
          }
          this.loadings.transaction = false;

           if(page)
          {
            this.scrollTable()
          }
        })
        .catch();
    },
    scrollTable()
    {
      if(window.innerWidth < 600)
      {
        document.getElementById('paymentCard').scrollIntoView()
      }
    },
    getReturnAndRefund(page = 1)
    {
      this.conditions.subscription = 'return/refund'

      let  p = ""
      {
        if(page){
          p = page
        }
        this.loadings.subscription = true
        this.axios.get('subscrption?subscription_range&retun_refund=true&user_company_id=' + this.common.user_company_id + '&page=' + p)
        .then( ({data}) => {
          if (!page) {
            this.pagination.subscription = data.meta;
          }
          this.loadings.subscription = false
          this.arrays.subscription = [];
          this.arrays.subscription = data.subscription;
        })
      }
    },
    paginateTransaction(page) {
      this.pagination.page.transaction = page;
      if (this.conditions.transaction == "all") {
        this.getTransaction(page);
      } else if (this.conditions.transaction == "day") {
        this.getDay(page);
      } else if (this.conditions.transaction == "week") {
        this.getWeek(page);
      }
    },
  },
};
</script>
<style  type="text/css" scope >
  .text-red{
    color: #9B0A00!important;
  }
  .v-data-table-header-mobile{
    display: none!important;
  }
</style>